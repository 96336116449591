<template>
  <div class="tg-notify d-flex flex-column">
    <div class="header">
      <h1 class="title">
        Добавление трендов
      </h1>
      <div class="import">
        <CInputFile
          accept=".xls, .csv, .xlsx"
          @change="onChangeInputFile"
          type="file"
          placeholder="Загрузите файл"
        />
        <CLoadingButtonCustom
          :loading="loadingImport"
          @click.native="sendFile"
        >
          {{ analyzed ? 'Отправить' : 'Проанализировать' }}
        </CLoadingButtonCustom>
      </div>
    </div>

    <div class="form d-flex flex-column">
      <CButtonGroup class="mb-4">
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.direction = 'up'"
          :variant="trend.direction === 'up' ? '' : 'outline'"
        >
          up
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.direction = 'down'"
          :variant="trend.direction === 'down' ? '' : 'outline'"
        >
          down
        </CButton>
      </CButtonGroup>
      <CButtonGroup class="mb-4">
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.tf = '30'"
          :variant="trend.tf === '30' ? '' : 'outline'"
        >
          m30
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.tf = '60'"
          :variant="trend.tf === '60' ? '' : 'outline'"
        >
          m60
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.tf = '120'"
          :variant="trend.tf === '120' ? '' : 'outline'"
        >
          m120
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.tf = '240'"
          :variant="trend.tf === '240' ? '' : 'outline'"
        >
          m240
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="trend.tf = '720'"
          :variant="trend.tf === '720' ? '' : 'outline'"
        >
          m720
        </CButton>
      </CButtonGroup>
      <v-select-input
        v-model="trend.ticker"
        :options="tickerList"
        :clearable="false"
        :loading="loadingList"
        :reduce="ticker => ticker.name"
        label="name"
        @open="getList"
        placeholder="Название тикера"
      />
      <CRow>
        <CCol col="6">
          <CInput
            label="Дата сработки"
            type="date"
            v-model="trend.date"
            placeholder="Дата сработки"
          />
        </CCol>
        <CCol col="6">
          <CInput
            label="Время сработки"
            type="time"
            step="1"
            v-model="trend.time"
            placeholder="Время сработки"
          />
        </CCol>
      </CRow>
      <CLoadingButtonCustom
        :loading="loading"
        color="primary"
        @click.native="sendTrend"
      >
        Отправить
      </CLoadingButtonCustom>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VSelectInput from "@/components/VSelectInput";

export default {
  name: "AddTrends",
  components: {
    VSelectInput
  },
  data() {
    return {
      loading: false,
      loadingList: false,
      loadingImport: false,
      file: null,
      analyzed: false,
      trend: {
        direction: '',
        tf: '',
        ticker: '',
        date: '',
        time: '',
      },
      tickerList: []
    }
  },
  methods: {
    onChangeInputFile(files) {
      this.analyzed = false
      if (files && files.length > 0) {
        this.file = files[0]
      } else {
        this.file = null
      }
    },
    sendFile() {
      this.loadingImport = true
      let formData = new FormData()
      formData.append('analyze', this.analyzed ? '0' : '1')
      formData.append('alert_file', this.file)
      axios.post('api/v2/trends/import', formData)
        .then(({data}) => {
          if (data.analyze == 1) {
            if (data?.wrongOrder) {
              this.$toastr.error('Неверный порядок строк. Нужно отсортировать по дате от более новых, к старым сработкам')
            } else {
              this.$toastr.success(`Будет записано ${data.all_count} строк с ${data?.startDateTime} по ${data?.endDateTime}`);
              this.analyzed = true
            }
          } else {
            this.$toastr.success(`Было успешно записано ${data.all_count} строк`)
          }
        })
        .finally(() => {
          this.loadingImport = false
        })
    },
    async sendTrend() {
      if (
        this.trend.direction === '' ||
        this.trend.tf === '' ||
        this.trend.ticker === '' ||
        this.trend.date === '' ||
        this.trend.time === ''
      ) {
        this.$toastr.error('Заполнены не все поля')
      } else {
        this.loading = true
        try {
          const body = {
            state: this.trend.direction,
            timeframe: this.trend.tf,
            ticker: this.trend.ticker,
            dateTime: this.trend.date + ' ' + this.trend.time
          }
          const {data} = await axios.post('/api/v2/trends/add', body)
          if (data?.data?.id) {
            this.$toastr.success('Тренд успешно добавлен')
          } else {
            this.$toastr.error('Внутренняя ошибка')
          }
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      }
    },
    getList(search = '') {
      if (this.tickerList.length === 0) {
        this.loadingList = true
        let query = search ? `search=${search}` : ''
        return axios.get(`/api/v1/trends_coins?${query}`)
          .then(({data}) => {
            this.tickerList = data?.data ? data.data : []
          })
          .finally(() => this.loadingList = false)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form {
  gap: 20px;
}
.import {
  display: flex;
  gap: 10px;
  align-items: center;

  .form-group {
    margin-bottom: 0;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.title {
  margin-bottom: 0;
}
</style>
