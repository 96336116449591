var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tg-notify d-flex flex-column" }, [
    _c("div", { staticClass: "header" }, [
      _c("h1", { staticClass: "title" }, [_vm._v(" Добавление трендов ")]),
      _c(
        "div",
        { staticClass: "import" },
        [
          _c("CInputFile", {
            attrs: {
              accept: ".xls, .csv, .xlsx",
              type: "file",
              placeholder: "Загрузите файл",
            },
            on: { change: _vm.onChangeInputFile },
          }),
          _c(
            "CLoadingButtonCustom",
            {
              attrs: { loading: _vm.loadingImport },
              nativeOn: {
                click: function ($event) {
                  return _vm.sendFile.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.analyzed ? "Отправить" : "Проанализировать") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "form d-flex flex-column" },
      [
        _c(
          "CButtonGroup",
          { staticClass: "mb-4" },
          [
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.direction === "up" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.direction = "up"
                  },
                },
              },
              [_vm._v(" up ")]
            ),
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.direction === "down" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.direction = "down"
                  },
                },
              },
              [_vm._v(" down ")]
            ),
          ],
          1
        ),
        _c(
          "CButtonGroup",
          { staticClass: "mb-4" },
          [
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.tf === "30" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.tf = "30"
                  },
                },
              },
              [_vm._v(" m30 ")]
            ),
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.tf === "60" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.tf = "60"
                  },
                },
              },
              [_vm._v(" m60 ")]
            ),
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.tf === "120" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.tf = "120"
                  },
                },
              },
              [_vm._v(" m120 ")]
            ),
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.tf === "240" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.tf = "240"
                  },
                },
              },
              [_vm._v(" m240 ")]
            ),
            _c(
              "CButton",
              {
                staticClass: "shadow-none button-custom text-white",
                attrs: {
                  color: "scroll-handler",
                  variant: _vm.trend.tf === "720" ? "" : "outline",
                },
                on: {
                  click: function ($event) {
                    _vm.trend.tf = "720"
                  },
                },
              },
              [_vm._v(" m720 ")]
            ),
          ],
          1
        ),
        _c("v-select-input", {
          attrs: {
            options: _vm.tickerList,
            clearable: false,
            loading: _vm.loadingList,
            reduce: (ticker) => ticker.name,
            label: "name",
            placeholder: "Название тикера",
          },
          on: { open: _vm.getList },
          model: {
            value: _vm.trend.ticker,
            callback: function ($$v) {
              _vm.$set(_vm.trend, "ticker", $$v)
            },
            expression: "trend.ticker",
          },
        }),
        _c(
          "CRow",
          [
            _c(
              "CCol",
              { attrs: { col: "6" } },
              [
                _c("CInput", {
                  attrs: {
                    label: "Дата сработки",
                    type: "date",
                    placeholder: "Дата сработки",
                  },
                  model: {
                    value: _vm.trend.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.trend, "date", $$v)
                    },
                    expression: "trend.date",
                  },
                }),
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { col: "6" } },
              [
                _c("CInput", {
                  attrs: {
                    label: "Время сработки",
                    type: "time",
                    step: "1",
                    placeholder: "Время сработки",
                  },
                  model: {
                    value: _vm.trend.time,
                    callback: function ($$v) {
                      _vm.$set(_vm.trend, "time", $$v)
                    },
                    expression: "trend.time",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "CLoadingButtonCustom",
          {
            attrs: { loading: _vm.loading, color: "primary" },
            nativeOn: {
              click: function ($event) {
                return _vm.sendTrend.apply(null, arguments)
              },
            },
          },
          [_vm._v(" Отправить ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }